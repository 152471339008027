import "styles/pages/page-road-to-thailand.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "components/Breadcrumbs";

const PageRoadToThailand = ({ pageContext }) => {
	const roadToThailand = pageContext.pageContent;
	return (
		<Layout headerColor="#F36900">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/road-to-thailand-bg-main.png")
						.default
				}
			/>
			<Breadcrumbs currentLocationLabel="VISA i wstęp do Tajlandii" />
			<section className="thailand-travel">
				<div className="container">
					<h2 className="section-title">
						{roadToThailand.thailandroadTitle}
					</h2>
					<div className="row">
						<div className="col-xl-6 text-center">
							<img
								src={
									require("assets/images/road-to-thailand-img-1.png")
										.default
								}
								alt=""
								className="img-fluid thailand-travel__img-1"
							/>
							<img
								src={
									require("assets/images/road-to-thailand-img-2.png")
										.default
								}
								alt=""
								className="img-fluid thailand-travel__img-2"
							/>
						</div>
						<div className="col-xl-4 offset-xl-2">
							<div
								className="thailand-travel__text"
								dangerouslySetInnerHTML={{
									__html: roadToThailand.thailandroadDesc,
								}}
							></div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageRoadToThailand;
